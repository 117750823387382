<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('user.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="
          flex flex-col
          sm:flex-row sm:items-end
          xl:items-start
          justify-end
        "
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn bg-theme-31 text-white sm:w-auto pb-1 pt-1"
            @click="$router.push('/users/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
          <button
            id="tabulator-print"
            class="
              btn btn-outline-secondary
              dark:text-white
              w-1/2
              sm:w-auto
              mr-2
              ml-2
            "
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.print') }}
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="
                dropdown-toggle
                btn btn-outline-secondary
                dark:text-white
                w-full
                sm:w-auto
              "
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('common.table.export') }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onDownloadPdf"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToPDF') }}
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToXLSX') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <!-- scrollbar-hidden -->
          <table
            class="table table-report sm:mt-2"
            id="tablePrint"
            ref="tableRef"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap">#</th>
                <!-- <th class="whitespace-nowrap text-center" id="ignore-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="selectAll"
                    @click="select"
                  />
                </th> -->
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="col.name === 'action' ? 'ignore-1' : ''"
                  class="text-center whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                <td class="w-40">
                  <div
                    class="
                      text-black
                      dark:text-white
                      text-xs
                      whitespace-nowrap
                      mt-0.5
                    "
                  >
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <!-- <td id="ignore-4">
                  <div class="text-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="row.id"
                      v-model="selected"
                    />
                  </div>
                </td> -->
                <td class="text-center flex items-center justify-center">
                  <app-list-item-image
                    :value="row.avatar"
                  ></app-list-item-image>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'fullName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'email') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'phoneNumber') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'tempPassword') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'maxNumberOfMenus') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'numberOfMenusUsed') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'hostingURL') }}
                  </div>
                </td>
                <td class="w-40">
                  <div
                    class="flex items-center justify-center"
                    :class="{
                      'text-theme-24': row.disabled,
                      'text-green dark:text-theme-30': !row.disabled
                    }"
                  >
                    <XSquareIcon
                      v-if="row.disabled"
                      class="w-4 h-4 mr-2 ml-2"
                    />
                    <CheckSquareIcon v-else class="w-4 h-4 mr-2 ml-2" />
                    {{
                      !row.disabled
                        ? i18n('common.activated')
                        : i18n('common.disabled')
                    }}
                  </div>
                </td>
                <td class="table-report__action w-56">
                  <div
                    class="
                      flex
                      justify-center
                      items-center
                      text-theme-24
                      space-x-4
                    "
                  >
                    <!-- <router-link
                      class="
                        text-theme-10
                        dark:text-theme-30
                        cursor-pointer
                        w-16
                        flex
                        justify-center
                        items-center
                        gap-2
                      "
                      :to="`/users/${row.id}/edit`"
                    >
                      <EditIcon class="w-4 h-4" /> {{ i18n('common.edit') }}
                    </router-link> -->
                    <!-- <router-link
                      class="
                        text-theme-10
                        dark:text-theme-30
                        cursor-pointer
                        w-20
                        flex
                        justify-center
                        items-center
                        gap-2
                      "
                      :to="`/users/${row.id}`"
                    >
                      <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                    </router-link> -->
                    <button
                      v-if="row.disabled"
                      @click="toggleUserStatus(row.id, false)"
                      class="
                        flex
                        justify-center
                        items-center
                        gap-2
                        p-2
                        transition
                        duration-300
                        ease-in-out
                        bg-white
                        dark:bg-dark-1
                        hover:bg-gray-200
                        dark:hover:bg-dark-2
                        rounded-md
                        text-green
                        dark:text-theme-30
                      "
                    >
                      <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                      {{ i18n('common.activate') }}
                    </button>
                    <button
                      v-if="!row.disabled"
                      @click="toggleUserStatus(row.id, true)"
                      class="
                        flex
                        justify-center
                        items-center
                        gap-2
                        p-2
                        transition
                        duration-300
                        ease-in-out
                        bg-white
                        dark:bg-dark-1
                        hover:bg-gray-200
                        dark:hover:bg-dark-2
                        rounded-md
                        text-theme-24
                        dark:text-theme-24
                      "
                    >
                      <LockIcon class="w-4 h-4 mr-1 ml-1" />
                      {{ i18n('common.disable') }}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="currentPage"
          :pageSize="pagination.limit"
          :showSizeChanger="true"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <!-- <DeleteModal item="user" @delete="doDestroyAllSelected()" /> -->
    <ActivateModal item="user" />
    <DisableModal item="user" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import feather from 'feather-icons'
// import DeleteModal from '@/components/modals/delete-modal.vue'
import ActivateModal from '@/components/modals/activate-modal.vue'
import DisableModal from '@/components/modals/disable-modal.vue'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'

export default defineComponent({
  components: {
    // DeleteModal,
    ActivateModal,
    DisableModal
  },
  setup() {
    const tableLimit = ref(10)
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }
    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selected,
      selectAll,
      tableLimit,
      tableRef
    }
  },
  data() {
    return {
      filterInput: {},
      filterFields: [
        { label: this.i18n('iam.filter.username'), value: 'fullName' },
        // { label: this.i18n('iam.filter.phoneNumber'), value: 'phoneNumber' },
        { label: this.i18n('iam.filter.email'), value: 'email' }
        // { label: this.i18n('iam.filter.status'), value: 'status' }
      ],
      columns: [
        {
          name: 'avatar',
          field: 'avatar',
          label: 'iam.fields.avatar',
          align: 'center'
          // required: true,
        },
        {
          name: 'userName',
          field: 'userName',
          label: 'iam.fields.userName',
          align: 'center'
          // required: true,
        },
        {
          name: 'email',
          field: 'email',
          label: 'iam.fields.email',
          align: 'center'
          // required: true,
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: 'iam.fields.phoneNumber',
          align: 'center'
          // required: true,
        },
        {
          name: 'tempPassword',
          field: 'tempPassword',
          label: 'iam.fields.tempPassword',
          align: 'center'
          // required: true,
        },
        {
          name: 'maxMenus',
          field: 'maxMenus',
          label: 'iam.fields.maxMenus',
          align: 'center'
          // required: true,
        },
        {
          name: 'menusUsed',
          field: 'menusUsed',
          label: 'iam.fields.menusUsed',
          align: 'center'
          // required: true,
        },
        {
          name: 'hostingURL',
          field: 'hostingURL',
          label: 'iam.fields.hostingURL',
          align: 'center'
          // required: true,
        },
        {
          name: 'status',
          field: 'status',
          label: 'iam.fields.status',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'user/list/rows',
      pagination: 'user/list/pagination',
      listLoading: 'user/list/loading'
      // destroyLoading: 'user/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
    // this.doMountTable(this.$refs.table)
  },
  methods: {
    ...mapActions({
      doFetch: 'user/list/doFetch',
      doFetchNextPage: 'user/list/doFetchNextPage',
      doFetchPreviousPage: 'user/list/doFetchPreviousPage',
      // doMountTable: 'user/list/doMountTable',
      // doChangeSort: 'user/list/doChangeSort',
      doChangePaginationCurrentPage: 'user/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'user/list/doChangePaginationPageSize',
      doToggleUserStatus: 'user/list/doToggleUserStatus'
      // doDestroy: 'user/list/doDestroy',
      // doEnableAll: 'user/list/doEnableAllSelected',
      // doDisableAll: 'user/list/doDisableAllSelected',
      // doDestroyAll: 'user/destroy/doDestroyAll'
    }),
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    async toggleUserStatus(id, disabled) {
      await this.doToggleUserStatus({ id, disabled })
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    async doFilter(filter) {
      if (!filter || !filter.value) return
      this.filterInput = {
        [filter.key]: filter.value
      }
      console.log('{{filterInput}}', this.filterInput)
      await this.doFetchFirstPage()
    },
    async doResetFilter() {
      this.filterInput = {}
      await this.doFetchFirstPage()
    },
    // async doEnableAllSelected() {
    //   await this.doEnableAll(this.selected)
    //   this.clearSelection()
    // },
    // async doDisableAllSelected() {
    //   await this.doDisableAll(this.selected)
    //   this.clearSelection()
    // },
    // async doDestroyAllSelected() {
    //   await this.doDestroyAll(this.selected)
    //   this.clearSelection()
    // },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        orderBy: 'createdAt',
        pagination: {
          firstPage: true,
          limit: 10,
          action: 'next',
          sortBy: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'userTable', 'users')
    },
    onDownloadPdf() {
      $h.downloadPdf('#tablePrint', 'users', [1, 5, 10])
    },
    onPrint() {
      print({
        printable: 'tablePrint',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 800,
        ignoreElements: ['ignore-1', 'ignore-2', 'ignore-3', 'ignore-4']
      })
    }
  }
})
</script>
<style scoped lang="scss">
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.dark {
  .table-report:not(.table-report--bordered):not(.table-report--tabulator) {
    td,
    th {
      background: none !important;
    }
  }
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
